<template>
  <!-- 网站模版预览页面 -->
  <div class="viewDiv" v-loading="loading" :class="'skin_theme_' + skin_theme">
    <startBuyDialog ref="startBuyDialog" @callback="doStartBuy" />
    <building ref="building" />

    <div class="topDiv">
      <el-row>
        <el-col :span="8" class="topDiv-left">
          <span @click="$router.push('/')">
            <i class="el-icon-arrow-left"></i>
            {{ $lang("details_title") }}
          </span>
        </el-col>
        <el-col :span="8" class="topDiv-center">
          <a
            @click="choose = 'pc'"
            class="option"
            :class="choose == 'pc' ? 'active' : ''"
            ><i class="el-icon-monitor"></i
          ></a>
          <a
            @click="choose = 'phone'"
            class="option"
            :class="choose == 'phone' ? 'active' : ''"
            ><i class="el-icon-mobile-phone"></i
          ></a>
        </el-col>
        <el-col :span="8" class="topDiv-right">
          <el-button
            @click="startBuy(id)"
            type="primary"
            style="background: #7952b3; border: 0"
          >
            {{ $lang("details_btn") }}
          </el-button>
        </el-col>
      </el-row>
    </div>
    <div class="bottomDiv">
      <iframe
        v-show="choose == 'pc'"
        style="width: 100%; height: 100%"
        :src="src"
        frameborder="0"
      ></iframe>

      <phoneView v-show="choose == 'phone'">
        <iframe
          style="width: 100%; height: 100%"
          :src="src"
          frameborder="0"
        ></iframe>
      </phoneView>
    </div>
  </div>
</template>

<script>
import phoneView from "@/components/editWeb/displayView/phone.vue";
import startBuyDialog from "@/components/search/startBuyDialog.vue";
import building from "@/components/search/building.vue";
import { do_start_buy } from "@/api/startBuy.js";
import { config } from "@/config/index.js";

export default {
  components: { phoneView, startBuyDialog, building },
  data() {
    return {
      choose: "pc",
      id: 0,
      src: "",
      loading: false,
    };
  },
  mounted() {
    let id = this.$route.query.id;
    this.id = id;
    this.src =
      config.web_host + config.template_view_url + "?template_id=" + id;
  },
  methods: {
    startBuy(id) {
      this.$refs.startBuyDialog.start(id);
    },
    doStartBuy(data) {
      let id = data.template_id;
      let web_name = data.web_name;

      //   this.loading = true;
      this.$refs.building.dialogVisible = true;
      do_start_buy({ template_id: id, web_name })
        .then((res) => {
          let web_id = res.data.web_id;
          setTimeout(() => {
            this.$notify({
              type: "success",
              message: "免费持有7日成功",
            });
            this.$router.push(
              "/editWeb?web_id=" + web_id + "&goBack=userCenter"
            );
          }, 5000);
        })
        .catch((err) => {
          if (err.code == 201) {
            this.$refs.building.dialogVisible = false;
          }
        })
        .finally(() => {
          //   this.loading = false;
          setTimeout(() => {
            this.$refs.building.dialogVisible = false;
          }, 5000);
        });
      console.log("startBuy template_id", id);
    },
  },
  computed: {
    language() {
      return this.$store.getters.language;
    },
    skin_theme() {
      return this.$store.getters.skin_theme;
    },
  },
};
</script>

<style lang="less" scoped>
.viewDiv {
  min-width: 1300px;
}
.topDiv {
  min-height: 60px;
  border-top: 1px solid #e5e9ef;
  line-height: 60px;
  background: #ffffff;
  font-size: 12px;
  padding-left: 20px;
  padding-right: 0;
  box-sizing: border-box;
  .topDiv-left {
    font-size: 13px;
    font-weight: bold;
    cursor: pointer;
  }
  .topDiv-center {
    font-size: 30px;
    text-align: center;
    .option {
      display: inline-block;
      width: 50px;
      position: relative;
      color: #ccc;
      cursor: pointer;
    }

    .option.active:before {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0px 5px 8px 5px;
      position: absolute;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      bottom: -3px;
    }
  }
  .topDiv-right {
    text-align: right;
    padding-right: 20px;
  }
}
.bottomDiv {
  width: 100vw;
  min-width: 1300px;
  height: calc(100vh - 64px);
  position: relative;
}

.skin_theme_one.viewDiv {
  .topDiv {
    border-bottom: 3px solid #615bff;
    .el-button--primary {
      background: #615bff !important;
    }
    .option.active {
      color: #615bff;
    }
    .option.active:before {
      border-color: transparent transparent #615bff transparent;
    }
  }

  .topDiv-left {
    .el-icon-arrow-left::before {
      color: #615bff !important;
      font-weight: bold;
    }
    color: #615bff;
  }
}
.skin_theme_two.viewDiv {
  .topDiv {
    border-bottom: 3px solid #151417;
    .el-button--primary {
      background: #151417 !important;
    }
    .option.active {
      color: #151417;
    }
    .option.active:before {
      border-color: transparent transparent #151417 transparent;
    }
  }
  .topDiv-left {
    .el-icon-arrow-left:before {
      color: #151417;
    }
    color: #151417;
  }
}
</style>