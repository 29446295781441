<template>
  <div class="phoneBackground">
    <div class="phoneView">
      <div class="one"></div>
      <div class="two"></div>
      <div class="three">
        <slot></slot>
      </div>
      <div class="four"></div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.phoneBackground {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;

  background: #444a53;
  display: flex;
  justify-content: center;
  align-items: center;
}
.phoneView {
  width: 407px;
  height: 810px;
  background: #fff;
  border-radius: 40px;

  position: relative;
  transform: scale(.8);


  .one {
    width: 11px;
    height: 11px;
    border-radius: 11px;
    background: #e3e3e3;
    position: absolute;
    top: 26px;
    left: 198px;
  }
  .two {
    width: 87px;
    height: 8px;
    border-radius: 8px;
    background: #e3e3e3;
    position: absolute;
    top: 50px;
    left: 160px;
  }
  .three {
    width: 375px;
    height: 660px;
    background: #e3e3e3;
    position: absolute;
    top: 76px;
    left: 16px;
  }
  .four {
    width: 43px;
    height: 43px;
    border-radius: 43px;
    background: #e3e3e3;
    position: absolute;
    bottom: 20px;
    left: 182px;
  }
}
</style>